export enum PageKeys {
    PAGE = 'page',
    PAGES = 'pages',
    PAGE_FOLLOWERS = 'pageFollowers',
}

export enum CommentKeys {
    COMMENTS = 'comments',
    REPLIES = 'replies',
}
